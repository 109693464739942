import React, {useState,useEffect} from "react"

import Layout from "../components/layout"
import { Segment,Header,Icon} from 'semantic-ui-react'
const NotFoundPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setTimeout(() => {setLoading(false)}, 1500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Layout>
      <Segment placeholder  textAlign='center' loading={loading}>          
        {!loading && <Header inline='centered' icon> <Icon name='warning' />ไม่มีหน้านี้<br/>NOT FOUND </Header>}
      </Segment>
      {/* <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
    </Layout>
  )
}

export default NotFoundPage
